<template>
  <div class="grid">
    <div class="col-12">
      <div class="font-medium text-3xl text-900 mb-3">
        <span class="text-primary text-5xl">/</span> Dívidas
      </div>
      <div class="text-500 mb-5">Listagem de dívidas</div>

      <div class="card">
        <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
          <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
            <InputText v-model="filters.filter" placeholder="Pesquisar" @change="loadRecords" />
          </div>
          <div class="p-fluid mt-2 w-full md:mt-0 md:w-5 ml-4">
            <Calendar v-model="filters.dateStart" :showIcon="true" placeholder="Data vencimento inicial"
              @date-select="loadRecords" @blur="loadRecords" />
          </div>
          <div class="p-fluid mt-2 w-full md:mt-0 md:w-5 ml-4">
            <Calendar v-model="filters.dateFinish" :showIcon="true" placeholder="Data vencimento final"
              @date-select="loadRecords" @blur="loadRecords" />
          </div>
          <div class="mt-2 ml-auto md:mt-0">
            <Button label="Novo" icon="pi pi-plus" class="p-button p-button-text mr-2"
              @click="$router.push({ name: 'dividas-new' })" />
          </div>
        </div>

        <Toast />

        <DataTable dataKey="id" :value="records" :row-hover="true" responsiveLayout="stack" breakpoint="640px">
          <template #empty> Nenhum registro encontrado. </template>

          <template #loading> Carregando registros. Aguarde ... </template>

          <Column field="nome" header="Nome"></Column>
          <Column field="cpfCnpj" header="Documento">
            <template #body="{ data }">
              {{ $filters.cpfCnpj(data.cpfCnpj) }}
            </template>
          </Column>
          <Column field="dataVencimento" header="Data de vencimento">
            <template #body="{ data }">
              {{ $filters.date(data.dataVencimento) }}
            </template>
          </Column>

          <Column bodyClass="text-right" headerStyle="width: 10rem;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="p-button-text p-button mr-2"
                @click="$router.push('/dividas/' + slotProps.data.id)" />
              <Button icon="pi pi-trash" class="p-button-text p-button mt-2"
                @click="confirmDeleteRecord(slotProps.data)" />
            </template>
          </Column>
        </DataTable>

        <Paginator :rows="perPage" :totalRecords="totalItems" @page="onPage"
          template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
          currentPageReportTemplate="Página {currentPage} de {totalPages}">
        </Paginator>

        <Dialog v-model:visible="deleteRecordDialog" :style="{ width: '450px' }" header="Confirmar" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="record">Tem certeza que deseja excluir <b>{{ record.name }}</b>?</span>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="deleteRecordDialog = false" />
            <Button label="Excluir" icon="pi pi-trash" class="p-button-outlined p-button-danger"
              @click="deleteRecord" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import CrudList from '../../components/abstract/CrudList.vue'
import CrudService from '@/services/crud'

export default {
  extends: CrudList,
  data() {
    return {
      service: new CrudService('/admin/dividas'),
    }
  }
}
</script>

<style scoped lang="scss">
</style>
